import { css } from "@emotion/core"
import React from "react"

function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      css={css`
        &:hover {
          circle {
            fill: white;
          }
          path {
            fill: #f46a02;
          }
        }
      `}
    >
      <circle cx="20" cy="20" r="20" fill="#8291BD"></circle>
      <path
        fill="#fff"
        d="M19.999 15.377a4.624 4.624 0 100 9.248 4.624 4.624 0 000-9.248zm0 7.627a3.004 3.004 0 110-6.007 3.004 3.004 0 010 6.007zM24.806 16.285a1.078 1.078 0 100-2.156 1.078 1.078 0 000 2.156z"
      ></path>
      <path
        fill="#fff"
        d="M28.533 14.112A4.604 4.604 0 0025.9 11.48a6.604 6.604 0 00-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.552 6.552 0 00-2.184.42 4.599 4.599 0 00-2.633 2.632 6.584 6.584 0 00-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.608 4.608 0 002.634 2.632 6.585 6.585 0 002.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.616 6.616 0 002.186-.42 4.613 4.613 0 002.633-2.632c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.583 6.583 0 00-.421-2.217zm-1.218 9.532a5.046 5.046 0 01-.311 1.688 2.986 2.986 0 01-1.712 1.71c-.535.2-1.1.304-1.67.312-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 01-1.669-.311 2.986 2.986 0 01-1.719-1.711 5.079 5.079 0 01-.311-1.67c-.043-.95-.053-1.217-.053-3.653 0-2.437 0-2.686.053-3.655a5.035 5.035 0 01.311-1.687c.305-.79.93-1.41 1.719-1.712a5.007 5.007 0 011.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055c.57.007 1.135.112 1.67.31a2.99 2.99 0 011.712 1.713c.197.535.302 1.099.311 1.669.043.95.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011v-.001z"
      ></path>
    </svg>
  )
}

export { InstagramIcon }
