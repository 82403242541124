import { css } from "emotion"

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export const formatDateForBlogs = (dateString: string) => {
  const date = new Date(dateString)
  const month = MONTHS[date.getMonth()]
  const day = date.getDate()
  const year = date.getFullYear()
  return `${month} ${day}, ${year}`
}

export const API_URL = "https://app.zavronfinserv.com"
export const CASHFREEURL = "https://test.cashfree.com"

export const OERDERID = 'order_078'

export type API_RESPONSE = {
  data: {
    status: boolean
    message: string
    payload: any
    paymentLink:any
  }
}

export const MOBILE_NO_REGEX = /^[6,7,8,9]\d{9}$/
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const LinkHoverCSS = css`
  &:hover {
    color: var(--primary-color) !important;
    cursor: pointer;
    /* border-bottom: 2px solid var(--primary-color); */
    /* padding-bottom: 5px; */
  }
`



const cloudinary_root = 'https://res.cloudinary.com/dhoiqmk4x/image/upload/'
export const customCloudinaryImage = (url: string, customs: string) => {
  if (url.includes('upload/v')) {
    return url.replace(cloudinary_root, cloudinary_root + customs)
  } else if (url.includes('upload/f_auto')) {
    return url.replace(cloudinary_root + 'f_auto/', cloudinary_root + customs)
  }
}

