import React from "react"

function BusinessLoan() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#001F79"
        d="M28.5 9H24V7.5a3 3 0 00-3-3h-6a3 3 0 00-3 3V9H7.5A4.5 4.5 0 003 13.5V27a4.5 4.5 0 004.5 4.5h21A4.5 4.5 0 0033 27V13.5A4.5 4.5 0 0028.5 9zM15 7.5h6V9h-6V7.5zM30 27a1.5 1.5 0 01-1.5 1.5h-21A1.5 1.5 0 016 27v-7.5h6V21a1.5 1.5 0 103 0v-1.5h6V21a1.5 1.5 0 103 0v-1.5h6V27zm0-10.5H6v-3A1.5 1.5 0 017.5 12h21a1.5 1.5 0 011.5 1.5v3z"
      ></path>
      <path fill="#F66B02" d="M33 16.5H3v3h30v-3z"></path>
      <path
        fill="#F66B02"
        d="M12 19h3v2.5a1.5 1.5 0 01-3 0V19zM21 19h3v2.5a1.5 1.5 0 01-3 0V19z"
      ></path>
    </svg>
  )
}

export { BusinessLoan }
