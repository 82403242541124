import React from "react"

function TwoWheeler() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="27"
      fill="none"
      viewBox="0 0 32 27"
    >
      <path
        fill="#001F79"
        d="M1 10.5v3h3V15h3v-1.5h4.5l4-3h1L15 17l-3 1 1 3 4.5-2 2-8.5H21l2 5 3-.5-2.5-6H28V4.5h-6L20.5 0h-6v3h4L20 7.5h-5l-4.5 3H1z"
      ></path>
      <circle
        cx="6.5"
        cy="20.5"
        r="5.25"
        stroke="#F66B02"
        strokeWidth="2.5"
      ></circle>
      <circle
        cx="25.5"
        cy="20.5"
        r="5.25"
        stroke="#F66B02"
        strokeWidth="2.5"
      ></circle>
    </svg>
  )
}

export { TwoWheeler }
