import React, { FC } from "react"

const HamburgerIcon: FC<{ color?: string }> = props => {
  const { color } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="22"
      fill="none"
      viewBox="0 0 32 22"
    >
      <path
        fill={color || "#121212"}
        fillOpacity={color ? "1" : "0.6"}
        d="M0 0H32V2H0z"
      ></path>
      <path
        fill={color || "#121212"}
        fillOpacity={color ? "1" : "0.6"}
        d="M0 10H32V12H0z"
      ></path>
      <path
        fill={color || "#121212"}
        fillOpacity={color ? "1" : "0.6"}
        d="M0 20H32V22H0z"
      ></path>
    </svg>
  )
}

export { HamburgerIcon }
