import React from "react"

function PersonalLoan() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#001F79"
        d="M18 3c-4.136 0-7.5 3.364-7.5 7.5S13.864 18 18 18s7.5-3.364 7.5-7.5S22.136 3 18 3zm0 12a4.505 4.505 0 01-4.5-4.5C13.5 8.019 15.519 6 18 6s4.5 2.019 4.5 4.5S20.481 15 18 15zm13.5 16.5V30c0-5.788-4.712-10.5-10.5-10.5h-6C9.21 19.5 4.5 24.212 4.5 30v1.5h3V30c0-4.136 3.364-7.5 7.5-7.5h6c4.136 0 7.5 3.364 7.5 7.5v1.5h3z"
      ></path>
      <circle cx="18" cy="10.5" r="6" stroke="#F66B02" strokeWidth="3"></circle>
    </svg>
  )
}

export { PersonalLoan }
