import { css } from "@emotion/core"
import React from "react"

function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      css={css`
        &:hover {
          circle {
            fill: white;
          }
          path {
            fill: #f46a02;
          }
        }
      `}
    >
      <circle cx="20" cy="20" r="20" fill="#8291BD"></circle>
      <path
        fill="#fff"
        d="M27.633 15.998c.013.175.013.348.013.522 0 5.326-4.053 11.462-11.46 11.462-2.282 0-4.402-.662-6.186-1.81.324.037.636.05.973.05a8.07 8.07 0 005.001-1.72 4.036 4.036 0 01-3.767-2.793c.249.037.499.061.761.061.361 0 .724-.05 1.061-.136a4.027 4.027 0 01-3.23-3.954v-.05c.537.3 1.16.486 1.82.512a4.022 4.022 0 01-1.796-3.354c0-.748.199-1.434.548-2.033a11.456 11.456 0 008.306 4.216c-.062-.3-.1-.611-.1-.924a4.024 4.024 0 014.028-4.027c1.16 0 2.207.486 2.943 1.271a7.956 7.956 0 002.556-.973c-.3.931-.93 1.72-1.771 2.22a8.071 8.071 0 002.319-.623 8.645 8.645 0 01-2.019 2.083z"
      ></path>
    </svg>
  )
}

export { TwitterIcon }
