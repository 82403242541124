import React from "react"

function UsedCarLoan() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <path
        fill="#001F79"
        d="M31.158 15.234l-2.052-6.158A4.493 4.493 0 0024.838 6H11.162a4.492 4.492 0 00-4.267 3.076l-2.052 6.158A3.004 3.004 0 003 18v7.5a2.99 2.99 0 001.559 2.614c-.02.1-.059.19-.059.293V31.5A1.5 1.5 0 006 33h1.5A1.5 1.5 0 009 31.5v-3h18v3a1.5 1.5 0 001.5 1.5H30a1.5 1.5 0 001.5-1.5v-3.093c0-.104-.039-.195-.058-.293A2.99 2.99 0 0033 25.5V18a3.005 3.005 0 00-1.842-2.766zM6 25.5V18h24l.003 7.5H6zM11.162 9h13.675c.646 0 1.22.411 1.424 1.026L27.92 15H8.08l1.658-4.974A1.5 1.5 0 0111.162 9z"
      ></path>
      <path
        fill="#F66B02"
        d="M9.75 24a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zM26.25 24a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
      ></path>
    </svg>
  )
}

export { UsedCarLoan }
